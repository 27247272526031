<template>
    <div id="login-container">
        <div class="stars"></div>
        <div id="menuTop">
            <div class="logobox">
                <!--<img :src="website.logo"/>-->
                <img src="~@/assets/images/login/logobai.png" style="margin-top: 10px;">
            </div>
            <!-- 菜单样式为active类 -->
            <nav>
                <!-- 当被点击，调用makeActive方法 -->
                <a href="#" class="home active">首页</a>
                <a href="#" class="study">学习中心</a>
                <!--<a href="#" class="study" v-on:click="makeActive('study')">学习中心</a>-->
                <a href="http://manage.xmy.xyz/admin.php/Login/login" class="teacher">教师中心</a>
                <a href="#" class="parent">家长中心</a>
                <!--<a href="#" class="myclass" v-on:click="makeActive('myclass')">我的班级</a>-->
                <!--<a href="#" class="about" v-on:click="makeActive('about')">关于我们</a>-->
            </nav>
            <!--{{active}}-->

            <div class="top_right">
                <div class="phonenum">
                    <img src="~@/assets/images/login/phone_icon.png">
                    <!-- <p>{{website.kefu_tel}}</p> -->
                </div>
                <div class="refresh">
                    <el-button icon="el-icon-refresh-left" type="primary" plain circle @click="refresh" />
                </div>
                <!--<div class="top_login">-->
                <!--<input class="login" type="button" value="登录" style=" cursor:pointer;">-->
                <!--<input class="register" type="button" value="注册">-->
                <!--</div>-->
            </div>
        </div>
        <div id="container" class="parentbg row-item-center">

            <!--左半布局-->
            <div class="halfbg column-item-center">
                <div class="leftbg column-item-center">
                    <div class="sjx" style="z-index: 101;">
                        <img class="sjxbig" src="~@/assets/images/login/sjxtm.png">
                    </div>
                    <div class="sjx2" style="z-index: 102;">
                        <img class="sjxbigtext" src="~@/assets/images/login/logotext.png">
                    </div>
                    <div id="preload">
                        <div class="cssload-loader">
                            <div class="cssload-inner cssload-one"></div>
                            <!--<div class="cssload-inner cssload-two"></div>-->
                            <div class="cssload-inner cssload-three"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!--右半布局-->
            <div class="halfbg column-item-center">
                <!--右侧 登录 模块-->
                <div class="login_box">
                    <div class="logandreg">
                        <!--loginbg.png-->
                        <div>登录</div>
                        <div>注册</div>
                    </div>
                    <el-form ref="ruleForm" :rules="rules" :model="form" label-width="70px">
                        <div class="namediv">
                            <el-form-item prop="username" label="用户名">
                                <el-input v-model="form.username" size="medium"></el-input>
                            </el-form-item>
                        </div>
                        <div class="passworddiv">
                            <el-form-item prop="password" label="密码">
                                <el-input v-model="form.password" size="medium" type="password">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="yanzhengmadiv">
                            <el-form-item prop="verify_code" label="验证码">
                                <el-input v-model="form.verify_code" size="medium"></el-input>
                            </el-form-item>
                            <img id="yanzhengma" :src="form.imgcode" @click="changeCodeImg" class="yzmpic"/>
                        </div>
                        <!--
                        <div class="namediv">
                            <p>用户名</p>
                            <input type="" AutoComplete="off" name="username">
                        </div>
                        <div class="passworddiv">
                            <p> 密 码 </p>
                            <input type="password" name="password">
                        </div>

                        <div class="yanzhengmadiv">
                            <p>验证码</p>
                            <input name="verify_code" autocomplete="off">
                            <img id="yanzhengma" src="http://www.yingyu-new.com/api/mycaptcha/math" onclick="this.src='http://www.yingyu-new.com/api/mycaptcha/math?'+Math.random()" class="yzmpic" />

                        </div>-->

                        <div class="signindiv">
                            <input type="button" value="登录" @click="login">
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
        <div id="footer">
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
               style="display: inline-block;padding: 0 10px;color: #fff; letter-spacing: 1px;">琼ICP备19002539号-4
            </a>
            <span style="padding: 0 5px 0 2px;">|</span>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #fff;">ICP证：琼B2-20220300</a>
            <span style="padding: 0 10px;">|</span>
<!--            <div style="width:300px;">-->
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010802000988"
                   style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                    <img src="@/assets/images/login/icon-wj.png" style="float:left;"/>
                    <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#fff;">
                        琼公网安备 46010802000988号
                    </p>
                </a>
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import * as stars from '@/assets/js/stars.js'
import $ from 'jquery'

export default {
    inject: ['reload'],
    data() {
        return {
            loading: false,
            form: {
                username: "",
                password: "",
                verify_code: "",
                imgcode: "",
                key: ""
            },
            rules: {
                username: [
                    {required: true, message: "请输入用户名", trigger: 'blur'}
                ],
                password: [
                    {required: true, message: "请输入密码", trigger: 'blur'}
                ],
                verify_code: [
                    {required: true, message: "请输入验证码", trigger: 'blur'}
                ]
            },
            imgcode: ""
        }
    },
    created() {
        this.changeCodeImg();
    },
    methods: {
        refresh() {
            this.reload()
        },
        login() {
            this.$refs.ruleForm.validate((e) => {
                if (!e) return;
                // 提交表单
                this.loading = true
                this.axios.post('/api/auth/login', this.form).then(res => {
                    console.log(res);
                    if (res.data.status_code == 200) {
                        this.$message('登录成功')
                        let data = res.data.data
                        this.$store.commit('login', data)
                        //this.$message('登录成功')

                        // 设置跳转页面 {name: 'study'}
                        //this.$router.push({name: 'index'})
                        this.$router.push({name: 'study'})
                    } else if (res.data.status_code == 202) {
                        this.$message(res.data.msg)
                    } else {
                        this.$message(res.data.msg)
                    }
                    this.changeCodeImg();
                    /*
                    // 存储到vuex
                    let data = res.data.data
                    // 存储到本地存储
                    this.$store.commit('login',data)
                    this.$message('登录成功')
                    this.loading = false
                    // 跳转后台首页
                    */
                }).catch(err => {
                    this.loading = false
                })
            })
        },
        //js处理获取请求后的值
        changeCodeImg() {
            let m = Math.round(Math.random() * 1e17);
            this.axios.get('/api/auth/captcha?t=' + m).then(res => {
                console.log(res);
                if (res.data.status_code == 200) {
                    this.form.imgcode = res.data.data.url.img
                    this.form.key = res.data.data.url.key
                } else {
                    this.$message('验证码获取失败')
                }
            }).catch(err => {
                this.loading = false
            })
        },//监听键盘事件
        key_event: function (code, d_code) {
            console.log("code:", code);
            console.log("d_code:", d_code);
            if (code == 13) {//回车
                this.login();
            }
        }
    },
    mounted() {
        let that = this;
        //键盘事件
        var key_state = 0; //未按下
        var d_code = '';
        $(document).keydown(function (event) {
            if (key_state != 1) {
                key_state = 1;
                d_code = event.keyCode;
            }
        });
        $(document).keyup(function (event) {
            if (event.keyCode == d_code) {
                key_state = 0;
                d_code = '';
            }
            console.log("event");
            console.log(event.keyCode);
            that.key_event(event.keyCode, d_code);

        });
    }
}
</script>

<style>
@import '~@/assets/css/xmysyindex.css';

#login-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(220% 105% at top center, #0e0f3f 10%, #0e0f3f 40%, #0e0f3f 65%, #0e0f3f);
    background-attachment: fixed;
    overflow: hidden;
    font-size: 14px;
}

</style>
